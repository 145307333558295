import {ScanNotFound} from './ScanNotFound';
import { BsHash, BsBraces } from "react-icons/bs";
import {VscMultipleWindows, VscFileBinary} from "react-icons/vsc";
import {AiOutlineScan} from "react-icons/ai";


export function ScanPreview(props) {
	if(!props.scan){
		return <ScanNotFound />;
	}
	return (
		<div className="container col-xl-6">
			<div className="shadow-lg p-4 m-4 bg-white rounded">
				<center>
					<h1><b>Iron</b><i>Wort</i>!</h1>
					<hr />
				</center>
				<div className="row">
					<div className="mb-5 rounded-3 bg-secondary bg-opacity-25">
						<p className="h5 m-2">No known threads found.
						<br />
						Dubbed name
						</p>
					</div>
					<br /><br />
					<div className="col overflow-hidden card card-body mb-3">
						<p className="h4">
						<BsHash />
							<strong>Hashes</strong>
						</p>
						<p>
							<strong>MD5: </strong>
							<span className="user-select-all">{props.scan.md5}</span>
							<br />
							<strong>sha256: </strong>
							<span className="user-select-all">{props.scan.sha256}</span>
							<br />
							<strong>SHA256: </strong>
							<span className="user-select-all">{props.scan.sha256}</span>
							<br />
							<strong>Imphash: </strong>
							<span className="user-select-all">{props.scan.imphash}</span>
							<br />
							<strong>SSDEEP: </strong>
							<span className="user-select-all">{props.scan.ssdeep}</span>
							<br />
							<strong>Rich PE hash: </strong>
							<span className="user-select-all">{props.scan.richPE}</span>
							<br />
							<strong>TLSH: </strong>
							<span className="user-select-all">{props.scan.tlsh}</span>
						</p>
					</div>

					<div className="col overflow-auto card card-body mb-3">
						<p className="h4">
							<VscFileBinary />
							<strong>File properties</strong>
						</p>
						<p>
							<strong>Names: </strong>
							<span className="user-select-all badge bg-info">{props.scan.fileProperties.name}</span>
							<br />
							<strong>MIME: </strong>
							<span className="user-select-all">{props.scan.fileProperties.mime}</span>
							<br />
							<strong>File type: </strong>
							<span className="user-select-all">{props.scan.fileProperties.fileType}</span>
							<br />
							<strong>Architecture: </strong>
							<span className="user-select-all">{props.scan.fileProperties.arch}</span>
							<br />
							<strong>Size: </strong>
							<span className="user-select-all">{props.scan.fileProperties.fileSize}</span>
							<br />
							<strong>Packer: </strong>
							<span className="user-select-all badge bg-dark">{props.scan.fileProperties.packer}</span>
							<br />
							<strong>Creation time: </strong>
							<span className="user-select-all">{props.scan.fileProperties.creationTime}</span>
							<br />
							<strong>First submission: </strong>
							<span className="user-select-all">{props.scan.fileProperties.submitTime}</span>
							<br />
							<strong>Icon: </strong>
							<img src="" alt="File icon" width="15" height="15" />
							<br />
						</p>
					</div>

					<div className="col overflow-auto card card-body mb-3">
						<p className="h4">
							<VscMultipleWindows />
							<strong>Similar samples</strong>
						</p>

						<p data-bs-toggle="collapse" href="#collapseSSDEEP" role="button">
							<strong>Similar SSDEEP samples:</strong> 100
						</p>
						<div className="collapse" id="collapseSSDEEP">
							<div className="card card-body">
								Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
							</div>
						</div>

						<p data-bs-toggle="collapse" href="#collapseRichPE" role="button">
							<strong>Similar RichPE samples:</strong> 100
						</p>
						<div className="collapse" id="collapseRichPE">
							<div className="card card-body">
								Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
							</div>
						</div>

						<p data-bs-toggle="collapse" href="#collapseIMPhash" role="button">
							<strong>Identical imphash samples:</strong> 100
						</p>
						<div className="collapse" id="collapseIMPhash">
							<div className="card card-body">
								Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
							</div>
						</div>

						<p data-bs-toggle="collapse" href="#collapseTLSH" role="button">
							<strong>Similar TLSH samples:</strong> 100
						</p>
						<div className="collapse" id="collapseTLSH">
							<div className="card card-body">
								Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
							</div>
						</div>

						<p data-bs-toggle="collapse" href="#collapseTLSHX" role="button">
							<strong>Similar TLSHX samples:</strong> 100
						</p>
						<div className="collapse" id="collapseTLSHX">
							<div className="card card-body">
								Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
							</div>
						</div>

						<p data-bs-toggle="collapse" href="#collapseDHICON" role="button">
							<strong>Similar dHash samples:</strong> 100
						</p>
						<div className="collapse" id="collapseDHICON">
							<div className="card card-body">
								Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
							</div>
						</div>


					</div>

					<div className="col overflow-auto card card-body mb-3">
						<p className="h4">
							<BsBraces />
							<strong>Yara matches</strong>
						</p>
						<p>
							<strong>Number of rules matching:</strong> N
							<br />
							<strong>Rules matching: </strong>
							<span className="badge bg-primary">Njrat</span>
						</p>
					</div>

					<div className="col overflow-auto card card-body mb-3">
						<p className="h4">
							<AiOutlineScan />
							<strong>PE/ELF/APK inspection</strong>
						</p>
						<p className="mt-2">
							<strong>PE/ELF/APK</strong> has <strong>N</strong> sections
						</p>
						<table className="table">
							<thead>
								<tr>
									<th scope="col">Section</th>
									<th scope="col">Entropy</th>
									<th scope="col">Raw size</th>
									<th scope="col">MD5</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className="badge bg-secondary">.text</span>
										&nbsp;
										<span className="badge bg-warning">exec</span>
									</td>
									<td>
										<span className="badge bg-danger">susp</span>
									</td>
									<td>size</td>
									<td>{props.scan.sha256}</td>
								</tr>
							</tbody>
						</table>
						<p>
							<strong>PE</strong> imports functions from <strong>N</strong> [.dll]
						</p>
						<p data-toggle="collapse" data-target="#collapse0iw" role="button">
							<strong>+</strong> a.dll
						</p>
						<div className="collapse" id="collapse0iw">
							<ul className="">
								<li><small>aFunc</small></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
import { useState } from "react";
import { Logo } from "./Logo";

export function Search() {

	const [query, setQuery] = useState(null);

	const inputCapture = e => {
		//const query = e.target.value;
		//console.log(query);
		setQuery(1)
		setTimeout(()=>{}, 1000);
	};

	return (
		<div className="container col-xl-4">
			<div className="shadow-lg p-5 mb-5 bg-white rounded">
				<center>
					<Logo />
					<div className="form mb-3">
						<input
							className="form-control"
							id="floatingInput"
							type="text"
							name="search"
							placeholder="Search samples by hash eg: md5, sha256, sha256.."
							onChange={inputCapture}
							required
							/>
						
					</div>
					<br />

					<ul className="list-group list-group-flush">
						<li className="list-group-item">An item</li>
					</ul>
				</center>
			</div>
		</div>
	)
}
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

import {Navbar} from './components/Navbar';
import {Uploader} from './components/Uploader';
import {Scan} from "./components/Scan";
import {Search} from "./components/Search";
import {Explore} from "./components/Explore";

export function App() {
	return (
		<Router>
			<Navbar />
				<Switch>
					<Route path="/file/:sha256" component={Scan} />
					<Route exact path="/home" component={Uploader} />
					<Route exact path="/search" component={Search} />
					<Route exact path="/search/:q" component={Search} />
					<Route exact path="/explore" component={Explore} />
					<Route path="*">
						<Redirect to="/home" />
					</Route>
				</Switch>
		</Router>
	);
}

export const accepted = [
	"application/octet-stream",
	"application/vnd.microsoft.portable-executable",
	"application/x-dosexec",
	"application/x-msdownload",
	"application/x-msdos-program",
	"application/x-ms-application",
	"application/x-ms-dos-executable"
];

export function fileBadge(file_object) {
	if(file_object.size > 10000000){
		return "badge bg-danger";
	}
	return "badge bg-success";
}

export function fileButton(file_object) {
	if(file_object.size > 10000000){
		return "btn btn-outline-danger";
	}else if(file_object.size > 0){
		return "btn btn-outline-primary";
	}else{
		return "btn btn-outline-secondary";
	}
}

export function filePercentageDisplay(file_object, percentage) {
	if(file_object.size < 10000000 ){
		return (
			<div className="progress mb-3 mt-3">
				<div className="progress-bar" role="progressbar" style={{width: percentage+"%"}} >{`${percentage}%`}</div>
			</div>
		);
	}
}

export function fileMessage(file_object, base64_object){
	if(file_object.size && base64_object){
		if(file_object.size < 10000000){
			return "File: loaded!";
		}else{
			return "File: oversize!";
		}
	}
	return "Allowed filetypes: sample.exe, binary.bin, lib.dll ... Max size: 10M";

}

export function fileNameTruncate(file_object) {
	if(file_object.name.length >= 30){
		return `${file_object.name.slice(0,30)}... `;
	}
	return `${file_object.name} `;;
}

export function fileSizeConverter(file_object) {
	if(file_object.size && file_object.size >= 1000 && file_object.size < 1000000){
		return `${(file_object.size/1000).toFixed(1)} KB`;
	}else if(file_object.size >= 1000000 && file_object.size < 1000000000){
		return `${(file_object.size/1000000).toFixed(1)} MB`;
	}else if(file_object.size >= 1000000000){
		return `${(file_object.size/1000000000).toFixed(1)} GB`;
	}
}

export function buttonAvailable(file_object, uploading, uploadFileToServer){
	if(file_object.size < 10000000){
		return(
		<button onClick={uploadFileToServer} type="button" className="btn btn-primary" >
			{uploading ? "Uploading": "Scan"}
		</button>
		);
	}
}

export function fileType(file_object){
	const mime = ['application/octet-stream', 'application/vnd.microsoft.portable-executable', 'application/x-dosexec', 'application/x-msdownload', 'application/x-msdos-program', 'application/x-ms-application', 'application/x-ms-dos-executable'];
	const ext = ['application', 'bin', 'dll', 'exe'];
	const extension = file_object.name.slice(file_object.name.length-3, file_object.name.length);
	if(ext.includes(extension) || mime.includes(file_object.type)){
		return true
	}
	return false
}


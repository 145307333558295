import { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";

import { ScanNotFound } from "./ScanNotFound";
import { ScanPreview } from "./ScanPreview";
import {URL} from "./utils/config";

export function Scan() {
	const sha256Validator = /[0-9A-Fa-f]{64}/g;
	const { sha256 } = useParams();
	const [fileInfo, setFileInfo] = useState(null);
	let timeout;

	useEffect(() => {
		fetchFileExists();

		return () => {
			//console.log("unmount");
			clearTimeout(timeout);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchFileExists = async () => {
		const fileInfoResHead = await fetch(`${URL}/sample/${sha256}`, {
			method: "HEAD",
		});

		if (timeout) clearTimeout(timeout);
		if (fileInfoResHead.ok) {
			const fileInfoRes = await fetch(`${URL}/sample/${sha256}`);
			const fileInfoResponse = await fileInfoRes.json();
			setFileInfo(fileInfoResponse);
		} else {
			//setTimeout(()=>fetchFileExists(), 5000);
			timeout = setTimeout(() => fetchFileExists(), 5000);
		}
	};

	if (!sha256Validator.test(sha256) || sha256.length !== 40) {
		return <Redirect to="/home" />;
	} else if (!fileInfo) {
		return <ScanNotFound />;
	} else {
		return <ScanPreview scan={fileInfo} />;
	}
}
import {useState} from 'react';
import { Redirect } from 'react-router-dom';

import {
	fileBadge,
	fileButton,
	filePercentageDisplay,
	fileMessage,
	fileNameTruncate,
	fileSizeConverter,
	buttonAvailable,
	fileType,
	accepted
} from "./utils/uploaderUtils";
import sha256 from "./utils/sha256";
import {URL} from "./utils/config";
import {Logo} from "./Logo"

import "./Uploader.css";

export function Uploader() {

	const [base64, setBase64] = useState();
	const [SHA1, setSHA1] = useState();
	const [fileObj, setFileObj] = useState({name:"Choose a sample...", size:null});
	const [percent, setPercent] = useState(0);
	const [pb, setPb] = useState("pb");
	const [uploading, setUploading] = useState(false);
	const [exists, setExists] = useState(null);

	const fileSelected = e => {
		const file = e.target.files[0];
		if(fileType(file)){
			setPb("");
			setFileObj(file);
			if(file.size < 10000000){ //Max file size = 10MB
				const frText = new FileReader();
				frText.readAsArrayBuffer(file);
				frText.onloadstart = () => {setPercent(0);};
				frText.onload = async () => {
					const hash = await sha256(frText.result);
					setSHA1(hash);
					const shaCheckQueue = await fetch(`${URL}/queue/${hash}`, {method: 'HEAD'});
					const shaCheckScanned = await fetch(`${URL}/sample/${hash}`, {method: 'HEAD'});
					if(shaCheckQueue.statusText === "Found") {
						await setExists(true);
					}else if(shaCheckScanned.statusText === "Found"){
						await setExists(true);
					}else{
						await setExists(false);
					}
				};
				frText.onprogress = e => {
					const percent = Math.round((e.loaded/e.total)*100);
					setPercent(percent);
				};
				frText.onloadend = () => {setPercent(100);};

				const frBase64 = new FileReader();
				frBase64.readAsDataURL(file);
				frBase64.onloadstart = () =>{setPercent(0);};
				frBase64.onload = () => {setBase64(frBase64.result);};
				frBase64.onprogress = e => {
					const percent = Math.round((e.loaded/e.total)*100);
					setPercent(percent);
				};
			}

		}
	}

	const uploadFileToServer = async () => {
		try {
			await setUploading(true);
				const reqOptions = {
					method: 'POST',
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						sha256: SHA1,
						fileName: fileObj.name,
						fileSize: fileObj.size,
						lastModified: fileObj.lastModified,
						fileObject: base64.split(",")[1]
					})
				};
			const upload = await fetch(`${URL}/upload/`, reqOptions);
			if(upload.status === 201){
				setExists(true);
			}
		} catch(e) {
			console.log(`Error: ${e}`);
		}
	}

	return(
		<>
		{exists === true && exists !== null ? <Redirect to={`https://api.ironwort.me/sample/${SHA1}`} />:""}
			<div className="container col-xl-4">
				<div className="shadow-lg p-5 mb-5 bg-white rounded">
					<center>
						<Logo />
						<form>
							<label className={fileButton(fileObj)} >
								<input
									onChange={fileSelected}
									type="file" name="file" required={true}
									accept={accepted}
								/>
									{fileNameTruncate(fileObj)}
									<span className={fileBadge(fileObj)}>{fileSizeConverter(fileObj)}</span>
							</label>
							<br />
							<br />
							<small className="text-muted">{fileMessage(fileObj, base64)}</small>
							<div className={pb}>
								{filePercentageDisplay(fileObj, percent)}
								{buttonAvailable(fileObj, uploading, uploadFileToServer)}
							</div>
						</form>
					</center>
				</div>
			</div>
		</>
	)
}
import {useEffect, useState} from 'react';
import { Logo } from './Logo';
import {URL} from "./utils/config";

export function Explore() {
	const [filesInfo, setFilesInfo] = useState(null);

	useEffect(() => {
		fetchFiles();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchFiles = async () => {
		const filesInfoRes = await fetch(`${URL}/samples/?limit=10`);
		const filesInfoResponse = await filesInfoRes.json();
		setFilesInfo(filesInfoResponse);
		
	};

	return (
		<div className="container col-xl">
			<div className="shadow-lg p-5 mb-5 bg-white rounded">
				<center>
					<Logo />

					<table className="table table-striped">
						<thead>
							<tr>
								<th scope="col">sha256</th>
								<th scope="col">Rules matching</th>
								<th scope="col">Added</th>
								<th scope="col">Last scanned</th>
							</tr>
						</thead>
						<tbody>
							{/* <tr>
								<td>{filesInfo? filesInfo[0].sha256 : "Loading.."}</td>
								<td>{filesInfo? filesInfo[0].fileProperties.arch : "Loading.."}</td>
								<td>19/11/2020</td>
								<td>26/08/2021</td>
							</tr> */}

							<tr>
								<td>8ae6749995658cc0512e2525eb53d3c4b0b25857</td>
								<td><span className="badge bg-info">buffer_overflow</span></td>
								<td>26/05/2022</td>
								<td>26/05/2022</td>
							</tr>
							<tr>
								<td>4f32bbfbdcc8bb20a9dacb6aa9a39aeb83c1e9ef</td>
								<td><span className="badge bg-warning">outdated_version</span></td>
								<td>26/05/2022</td>
								<td>26/05/2022</td>
							</tr>
							<tr>
								<td>aa00f486b9944279e563246a68b70f1dad4f442e</td>
								<td><span className="badge bg-danger">malware</span></td>
								<td>24/05/2022</td>
								<td>24/05/2022</td>
							</tr>
						</tbody>
					</table>
				</center>
			</div>
		</div>
	)
}
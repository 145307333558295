import {NavLink} from "react-router-dom";
import {BsHouse, BsSearch} from "react-icons/bs";
import {IoIosGlobe,IoIosCellular} from "react-icons/io"
import { VscCircleLargeFilled } from "react-icons/vsc";
import {IconContext} from "react-icons";

import "./Navbar.css";

import logo from "./assets/logo.png"

export function Navbar() {
	return (
		<nav className="navbar navbar-light bg-light">
			<div className="container-fluid">
			<NavLink className="navbar-brand" to="/" label="Home">
				<img className="d-inline-block align-top logo" alt="logo" src={logo} />
				<b> Iron</b><i>Wort</i>! online scanner
			</NavLink>
			<ul className="nav nav-pills nav-fill">
				<li className="nav-item">
					<NavLink to="/home" label="Home" activeClassName="active" className="nav-link">
							<BsHouse />
							Home
					</NavLink>
				</li>
				<li className="nav-item" disabled>
					<NavLink to="/search" label="Search" activeClassName="active" className="nav-link disabled">
							<BsSearch />
							Search
					</NavLink>
				</li>
				<li className="nav-item" disabled>
					<NavLink to="/explore" label="Explore" activeClassName="active" className="nav-link disabled">
							<IoIosGlobe />
							Scans
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink to="/status" label="Back-end status" activeClassName="active" className="nav-link disabled">
							<IconContext.Provider value={{ color: 'green'}}>
								<div>
								Back-end: <VscCircleLargeFilled />
								</div>
							</IconContext.Provider>
					</NavLink>
				</li>
			</ul>
			</div>
		</nav>
	)
}
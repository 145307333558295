import {useParams} from "react-router-dom";

export function ScanNotFound() {
	const { sha256 } = useParams();

	return (
		<div className="container col-xl-6">
			<div className="shadow-lg p-4 m-4 bg-white rounded">
				<center>
					<h1><b>Iron</b><i>Wort</i>!</h1>
					<hr />
				</center>
				<div className="col overflow-hidden card card-body mb-3">
						<p className="h4">
							<strong>Sample is in queue: </strong>
							{sha256}
						</p>
						<center>
						<div className="spinner-border text-primary" role="status">
							<span className="visually-hidden">
								<strong>
									Loading sample: {sha256}
								</strong>
							</span>
						</div>
						</center>
				</div>
			</div>
		</div>
	)
}